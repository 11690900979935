import React from 'react';


const Skills = () => {

    return (
        <>
            <section className="bg-monss py-10">
                <h2 className="text-white container mx-auto px-5 font-sans md:font-light text-3xl md:text-5xl leading-tight" >Things we're good at</h2>
                <div className="container mx-auto p-5 divide-y-2 divide-white flex flex-col space-y-14 md:space-y-0 gap-14" >
                    <div></div>
                    <SkillItem
                        title="PRINT MARKETING"
                        description="Digital printing gives your product the dramatic effect you’ve been seeking all this time. With the ability to print on high gloss paper, you’ll be sure to impress your clients with our eye-catching creations ranging from business cards, to postcards or even VIP tickets to your company’s next big event! Holiday cards and customized calendars are a great way to let your clients know you are thinking of them even in the off-season at a fraction of the cost of offset printing."
                        options={[
                            'Digital Printing',
                            'Offset Printing',
                            'Brand Strategy',
                            'Brochures',
                            'Menus and much more',
                            'Campaign Development'
                        ]} />

                    <SkillItem
                        title="STATIONARY PRINTING"
                        description="Let The Printman make the right impression on your customers. From design to output, we offer endless options. We can produce business cards with foil, UV coating, matte or gloss lamination, raised printing or even embossed. Die cut business card options are also available to help you stand out from the crowd allowing you to have custom shaped business cards."
                        options={[
                            'Business Cards',
                            'Envelopes',
                            'LETTERHEADS',
                            'NCR Carbonless',
                            'Signage',
                            'Banners'
                        ]} />

                    <SkillItem
                        title="OFFSET PRINTING"
                        description="Offset Printing uses actual ink as opposed to digital printing which uses an electronically charged powder (Toner). Using Ink such as pantones gives you the highest level of colour accuracy and quality. This helps us provide you with your branded stationery in your companies colours that helps your customers identify your business. The Printman Peshawar can also provide raised printing, printing in metallic colours and UV (Varnish) or finishing. UV offset finishing adds a gloss or matte finish your printed product. You can create emphasis on a part of design creating dynamic product that will catch your customer interest and attention."
                        options={[
                            'Brochures',
                            'Business Cards',
                            'Calendars',
                            'Carbonless (NCR) Forms',
                            'Envelopes',
                            'Flyers'
                        ]} />
                </div>
            </section>
        </>
    )
}

interface SkillItemProps {
    title: string
    description: string
    options: string[]
}

const SkillItem = ({ title, description, options }: SkillItemProps) => {
    return (
        <div className="text-white">
            <h2 className="text-sm font-medium py-3">{title}</h2>
            <div className="flex flex-col md:flex-row gap-10 md:gap-20 my-5 justify-evenly">
                <div className="hidden lg:block flex-1" />
                <p className="flex-1 text-xl max-w-xs">
                    {description}
                </p>
                <ul className="flex-1 divide-white divide-y">
                    {options.map(d => {
                        return (
                            <li key={d} className="text-xl py-4" >{d}</li>
                        )
                    })
                    }
                </ul>
            </div>
        </div>
    )
}


export default Skills;